<template>
  <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2293_63043)">
      <path d="M3.125 6.37549L1.25 8.25049M1.25 8.25049L3.125 10.1255M1.25 8.25049H13.75M5.625 3.87549L7.5 2.00049M7.5 2.00049L9.375 3.87549M7.5 2.00049V14.5005M9.375 12.6255L7.5 14.5005M7.5 14.5005L5.625 12.6255M11.875 6.37549L13.75 8.25049M13.75 8.25049L11.875 10.1255" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_2293_63043">
        <rect width="15" height="15" fill="white" transform="translate(0 0.750488)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconMove',
}
</script>
